import { createRouter, createWebHistory } from 'vue-router';
import homeRoutes from '@/modules/home/home-router';
import autheRoute from '@/modules/authentication/authentication-router';
import rmaRoutes from '@/modules/rma/rma-router';
import sparepartsRouters from '@/modules/spare_parts/spare-parts-router';


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...homeRoutes, ...autheRoute, ...rmaRoutes, ...sparepartsRouters
  
  ],
});


router.beforeEach((to, from, next) => {
  const user = localStorage.getItem('user');
  if (to.matched.some((record) => record.meta.notLoggedIn)) {
    if (user) {
      next({ name: 'home' });
    } else {
      next();
    }
  } 
  
  else {

    if (user) {
      next();
    } else {
      next({ name: 'username' });
    }
  }
  
})


export default router
