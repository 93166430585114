import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import Axios from 'axios';

import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

// Axios.defaults.baseURL = 'http://localhost:8000/'; // base url of the api.

Axios.defaults.baseURL = 'https://' + (process.env.VUE_APP_BASE_URL || 'localhost:8000');
// Axios.defaults.baseURL = 'https://staging-backend.dshopdocs.com/'; //stage
// Axios.defaults.baseURL = 'https://backend-docs.dshop.com.au/';  //live

createApp(App).use(store).use(BootstrapVue3).use(router).mount('#app')
